import CategoriesPostModel from '../categories/models/categories-post.model';
import imageInput from '@/mixins/image-input.js';

export default {
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
// TODO: yoxlamaq
            let data = new CategoriesPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("categories/updateItem", {id: this.id, data: formData});
        }
    },
    created() {
        this.$store.dispatch("categories/getItemById", this.id).then((res) => {
            this.item = res;
        });
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    mixins: [imageInput],

}
